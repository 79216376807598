import logo from './logo.svg';
import './App.css';

function App() {
  return (
    
    <div className="App">
      <div class="navbar">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
               <a class="navbar-brand" href="#">MyPortfolio</a>
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                      <a class="nav-link" aria-current="page" href="#">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#">About</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </div>
          
          <div class="container">
            <div class="textbox">
              <p>Welcome to my website using bootstrap and react!! My name is Sahil and this is my portfolio project to display my web development skills. A work still in progress :)</p>
            </div>
            <img src={logo} classname="app-logo" alt="logo" />
          </div>
    </div>
  );
}

export default App;
